<template>
	<div>
		<h6>Datos de la empresa</h6>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row dense>
				<v-col cols="12" xl="3" lg="3" md="4" sm="4">
					<div class="form-group" :class="{ 'error--text': errors.number }">
						<label class="control-label">Número</label>
						<el-input v-model="form.number" :maxlength="11" :readonly="true"></el-input>
						<small
							class="error--text"
							v-if="errors.number"
							v-text="errors.number[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="5" lg="5" md="4" sm="4">
					<div class="form-group" :class="{ 'error--text': errors.name }">
						<label class="control-label">Nombre</label>
						<el-input v-model="form.name"></el-input>
						<small
							class="error--text"
							v-if="errors.name"
							v-text="errors.name[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<div class="form-group" :class="{ 'error--text': errors.trade_name }">
						<label class="control-label">Nombre comercial</label>
						<el-input v-model="form.trade_name"></el-input>
						<small
							class="error--text"
							v-if="errors.trade_name"
							v-text="errors.trade_name[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<div class="form-group" :class="{ 'error--text': errors.web_address }">
						<label class="control-label">Dirección web</label>
						<el-input v-model="form.web_address"></el-input>
						<small
							class="error--text"
							v-if="errors.web_address"
							v-text="errors.web_address[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<div class="form-group" :class="{ 'error--text': errors.telephone }">
						<label class="control-label">Telefono</label>
						<el-input v-model="form.telephone" placeholder="+51952243996"></el-input>
						<small
							class="error--text"
							v-if="errors.telephone"
							v-text="errors.telephone[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<div class="form-group" :class="{ 'error--text': errors.whatsapp_number }">
						<label class="control-label">N° WhatsApp</label>
						<el-input
							v-model="form.whatsapp_number"
							placeholder="+51952243997"
						></el-input>
						<small
							class="error--text"
							v-if="errors.whatsapp_number"
							v-text="errors.whatsapp_number[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<div class="form-group">
						<label class="control-label">Logo</label>
						<el-input v-model="form.logo" :readonly="true">
							<el-upload
								slot="append"
								:headers="headers"
								:data="{ type: 'logo' }"
								:action="`${appUrlBackEnd}/api/companies/upload-logo`"
								:before-upload="beforeUpload"
								:on-success="successUpload"
								:show-file-list="false"
							>
								<el-button type="primary" icon="el-icon-upload"></el-button>
							</el-upload>
						</el-input>
						<!-- <div class="error--text"><small>Se recomienda resoluciones 700x300</small></div> -->
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="12">
					<el-divider>Entorno del sistema</el-divider>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="6" lg="6" md="6">
					<div class="form-group" :class="{ 'error--text': errors.soap_send_id }">
						<label class="control-label">Envío a:</label>
						<el-select v-model="form.soap_send_id">
							<el-option
								v-for="(option, index) in soapSends"
								:key="index"
								:value="index"
								:label="option"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.soap_send_id"
							v-text="errors.soap_send_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="6" lg="6" md="6">
					<div class="form-group" :class="{ 'error--text': errors.soap_type_id }">
						<label class="control-label">Modo </label>
						<el-select v-model="form.soap_type_id">
							<el-option
								v-for="option in soapTypes"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.soap_type_id"
							v-text="errors.soap_type_id[0]"
						></small>
					</div>
				</v-col>
			</v-row>

			<template v-if="form.soap_type_id == '02'">
				<v-row dense>
					<v-col cols="12" xl="12">
						<el-divider>Usuario Secundario Sunat</el-divider>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="6" lg="6" md="6" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.soap_username }">
							<label class="control-label">Usuario</label>
							<el-input v-model="form.soap_username"></el-input>
							<div class="error--text">
								<small>RUC + Usuario Ejemplo: 01234567890ELUSUARIO</small>
							</div>
							<small
								class="error--text"
								v-if="errors.soap_username"
								v-text="errors.soap_username[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="6" lg="6" md="6" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.soap_password }">
							<label class="control-label">Contraseña</label>
							<el-input
								v-model="form.soap_password"
								type="password"
								show-password
							></el-input>
							<small
								class="error--text"
								v-if="errors.soap_password"
								v-text="errors.soap_password[0]"
							></small>
						</div>
					</v-col>
				</v-row>
			</template>
			<v-row dense v-if="form.soap_send_id == '02'">
				<v-col cols="12" xl="12">
					<div class="form-group" :class="{ 'error--text': errors.soap_url }">
						<label class="control-label">Url OSE</label>
						<el-input v-model="form.soap_url"></el-input>
						<small
							class="error--text"
							v-if="errors.soap_url"
							v-text="errors.soap_url[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="primary" native-type="submit" :loading="loadingSubmit">
						<template v-if="loadingSubmit">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</div>
</template>

<script>
import AppConfig from 'Constants/AppConfig';
export default {
	data() {
		return {
			appUrlBackEnd: AppConfig.appUrlBackEnd,
			headers: {
				Authorization: null,
			},
			loadingSubmit: false,
			resource: 'companies',
			errors: {},
			form: {},
			soapSends: [],
			soapTypes: [],
		};
	},
	// beforeCreate() {

	// },
	async created() {
		await this.initForm();
		await this.getTables();
		await this.getRecord();
	},
	
	methods: {
		getTables() {
			this.$http.get(`${this.resource}/tables`).then((response) => {
				this.soapSends = response.data.soapSends;
				this.soapTypes = response.data.soapTypes;
			});
		},
		async beforeUpload(file) {
			const accessToken = localStorage.getItem('access_token')

			this.headers.Authorization = `Bearer ${accessToken}`;

			const isIMG =
				file.type === 'image/jpeg' ||
				file.type === 'image/png' ||
				file.type === 'image/svg+xml' ||
				file.type === 'image/jpg';
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isIMG) this.$message.error('La imagen no es valida!');
			if (!isLt2M) this.$message.error('La imagen excede los 2MB!');

			return isIMG && isLt2M;
		},
		getRecord() {
			// const accessToken = document.cookie.replace(
			// 	/(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
			// 	'$1'
			// );

			// this.headers.Authorization = `Bearer ${accessToken}`;

			this.$http.get(`/${this.resource}/record`).then((response) => {
				this.form = response.data.data;
			});
		},
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				identity_document_type_id: '06000006',
				number: null,
				name: null,
				trade_name: null,
				telephone: null,
				whatsapp_number: null,
				soap_send_id: '01',
				soap_type_id: '01',
				soap_username: null,
				soap_password: null,
				soap_url: null,
				certificate: null,
				logo: null,
				web_address: null,
			};
		},
		submit() {
			this.errors = {};
			this.loadingSubmit = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loadingSubmit = false;
				});
		},
		successUpload(response) {
			if (response.success) {
				this.$message.success(response.message);
				this.form[response.type] = response.name;
			} else {
				this.$message({ message: 'Error al subir el archivo', type: 'error' });
			}
		},
	},
};
</script>
